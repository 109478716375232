<template>
    <div>
        <app-loader :value="$apollo.loading"/>
        <v-card flat tile v-if="accountData && adminProducts">
            <v-container>
                <v-data-table
                        :headers="headers"
                        :items="adminProducts"
                        sort-by="name"
                >
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-toolbar-title>All products</v-toolbar-title>
                            <v-divider
                                    class="mx-4"
                                    inset
                                    vertical
                            ></v-divider>
                            <v-spacer></v-spacer>
                            <modal
                                    width="800"
                                    btn-text="Add new products"
                                    title="Add a new product"
                                    subheader="Enter a product name, category and variants below. You can add pricing later"
                                    persistent
                                    v-model="dialog"
                            >
                                <v-form v-model="valid">
                                    <v-card-text class="pb-0">
                                        <v-row>
                                            <v-col cols="12">
                                                <text-field
                                                      id="product-name"
                                                        label="Product name"
                                                        v-model="form.name"
                                                        :rules="[rules.required]"
                                                ></text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <autocomplete
                                                        :allow-add="true"
                                                        label="Category"
                                                        id="category"
                                                        :items="accountData.categories"
                                                        v-model="form.category"
                                                        :rules="[rules.required]"
                                                >
                                                </autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                    <v-card-title class="primary-title justify-center pt-0">Variants</v-card-title>
                                    <v-card-text class="body-2 justify-center text-center pb-0">
                                        Help your customers by showing how many items you have available for rent in each
                                        category
                                    </v-card-text>
                                    <v-card-text>
                                        <v-form
                                                v-for="i in formCount" :key="i"
                                                :ref="`form-${i}`"
                                                v-model="formsetValid[i-1]"
                                        >
                                            <v-row>
                                                <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly">
                                                    <v-subheader>
                                                        Variant {{i}}
                                                    </v-subheader>
                                                </v-col>
                                                <v-col cols="12" md="3" class="pb-0">
                                                    <autocomplete
                                                            :label="store.labelOne"
                                                            v-model="forms[i-1].size"
                                                            :allow-add="true"
                                                            :items="store.labelOneChoices"
                                                            item-text="value"
                                                            item-value="value"
                                                    >
                                                    </autocomplete>
                                                </v-col>
                                                <v-col cols="12" md="3" class="pb-0">
                                                    <autocomplete
                                                            :label="store.labelTwo"
                                                            item-value="value"
                                                            v-model="forms[i-1].gender"
                                                            :items="store.labelTwoChoices"
                                                            :allow-add="true"
                                                    >
                                                    </autocomplete>
                                                </v-col>
                                                <v-col cols="12" md="3" class="pb-0">
                                                    <autocomplete
                                                            :label="store.labelThree"
                                                            v-model="forms[i-1].brand"
                                                            :items="store.labelThreeChoices"
                                                            :allow-add="true"
                                                    >
                                                    </autocomplete>
                                                </v-col>
                                                <v-col cols="12" md="3" class="pb-0">
                                                    <text-field
                                                            label="Quantity"
                                                            :id="`quantity-${i}`"
                                                            v-model="forms[i-1].quantity"
                                                            :rules="[rules.required, rules.integer, rules.positive]"
                                                    >

                                                    </text-field>
                                                </v-col>
                                            </v-row>

                                        </v-form>
                                        <v-row align="center" justify="center">
                                            <v-col cols="12" md="5">
                                                <v-btn class="mr-2" outlined :disabled="formCount < 2" rounded @click="removeForm">Remove last row</v-btn>
                                                <v-btn class="ml-2" outlined :disabled="formCount >= 5" rounded @click="addForms">Add more</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>


                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <btn
                                                :disabled="!allFormsValid"
                                                :method="saveProduct"
                                                @complete="redirectToProdPage"
                                        >
                                            Create product
                                        </btn>
                                    </v-card-actions>
                                </v-form>
                            </modal>
                        </v-toolbar>
                    </template>

                    <template v-slot:item.category="{ item }">
                        {{ item.category }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon @click="$router.push(`/product/${item.id}`)" small>mdi-pencil</v-icon>
                        <archive-product :product="item" @archived="refreshProduct"/>
                    </template>

                    <template v-slot:no-data>
                        You haven't added any products yet... click the button above to create one
                    </template>
                </v-data-table>
            </v-container>
        </v-card>


    </div>
</template>

<script>
    import { accountData, createProduct, user, adminProducts, stores } from '@/graphql'
    import { mapState } from 'vuex'

    export default {
        apollo: {
          user,
          stores,
          accountData,
          adminProducts: {
            query: adminProducts,
            variables () {
              return {
                store: this.$store.state.selectedStore
              }
            },
            skip () {
              return !this.$store.state.selectedStore
            }
          }
        },
        computed: {
            ...mapState(['selectedStore']),
            store () {
                if (this.selectedStore) {
                    return this.stores.find(store => store.hashId === this.selectedStore)
                }
                return null
            },
            allFormsValid () {
                var self = this;
                var allValid = Object.keys(this.formsetValid).every(function(k){ return self.formsetValid[k] });
                return allValid && this.valid
            }
        },
        name: 'Inventory',
        data: () => ({
            dialog: false,
            valid: null,
            form: {
                name: null,
                category: null,
            },
            forms: [{ size: null, gender: null, quantity: null, brand: null}],
            formsetValid: {},
            formCount: 1,
            rules: {
                required: v => !!v || 'This field is required',
                numeric: v => !isNaN(v) || 'Must be a number',
                integer: v => /^\d+$/.test(v) || 'Must be a whole number',
                positive: v => v > 0 || 'Quantity must be at least 1',
            },
            headers: [
                {text: 'Product', value: 'name'},
                {text: 'Category', value: 'category'},
                {text: 'Price', value: 'price', sortable: false},
                {text: 'Quantity available', value: 'assetCount'},
                {text: 'Actions', value: 'actions', sortable: false},
            ]
        }),
        methods: {
            saveProduct() {
                return this.$apollo.mutate({
                    mutation: createProduct,
                    variables: {
                        store: this.$store.state.selectedStore,
                        name: this.form.name,
                        category: this.form.category,
                        assets: this.forms
                    }
                })
            },
            redirectToProdPage({data}) {
                this.$apollo.queries.adminProducts.refetch();
                const {product} = data.createProduct;
                this.$router.push(`/product/${product.id}`)
            },
            addForms () {
                this.forms.push({ size: null, gender: null, quantity: null, brand: null});
                this.formCount ++
            },
            removeForm () {
                this.forms.pop();
                this.formCount --
            },
            refreshProduct () {
                this.$apollo.queries.adminProducts.refetch();
            }

        }

    }
</script>


